<template>
  <v-form>
    <v-alert
      v-if="alert"
      border="left"
      colored-border
      color="success"
      elevation="2"
      class="alert-edit"
      type="success"
      transition="fade-transition">
      Contact successfully updated.
    </v-alert>
    <v-overlay :value="overlay">
     <v-progress-circular
       indeterminate
       size="64"
     ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col
        cols="12"
      >
         <v-autocomplete
          v-model="businessSelected"
          :items="businessItems"
          :loading="isBusinessLoading"
          :search-input.sync="businessSearch"
          hide-no-data
          hide-details="auto"
          item-text="name"
          item-value="id"
          label="Select business"
          placeholder="Start typing to Search"
          prepend-icon="mdi-domain"
          return-object
          @change="selectBusiness"
          :error="(businessSelected == '' || !businessSelected) && showError"
          :rules="[v => !!v || 'Field is required']"
        ></v-autocomplete>
      </v-col>
      <v-col
        cols="12"
      >
       <v-select
         v-model="contact.referral_type"
         :items="typeitems"
         item-value="id"
         label="Select referral type"
         outlined
         prepend-icon="mdi-file-document"
         hide-details="auto"
         >
        </v-select>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-checkbox
          v-model="contact.intake_completed"
          outlined
          label="Intake Completed?"
          hide-details="auto"
          :class="{ 'ml-6': $vuetify.breakpoint.mdAndUp }"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-checkbox
          v-model="contact.connect"
          outlined
          label="Connect?"
          hide-details="auto"
        ></v-checkbox>
      </v-col>
      <v-col
        v-if="contact.referral_type == 2"
        cols="12"
      >
       <v-select
         v-model="contact.program_name"
         :items="programItems"
         item-value="id"
         item-text="name"
         label="Select program"
         outlined
         prepend-icon="mdi-file-document"
         hide-details="auto"
       />
    </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-menu
          ref="menu"
          v-model="date_menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="formatted_date"
            label="Date"
            prepend-icon="mdi-calendar"
            hint="MM/DD/YYYY format"
            persistent-hint
            readonly
            v-bind="attrs"
            v-on="on"
            ></v-text-field>
          </template>
        <v-date-picker
          v-model="contact.date_created"
          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
          min="1950-01-01"
          @change="datePickerSave"
        ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="contact.status"
          :items="statusItems"
          item-value="id"
          item-text="name"
          label="Status"
          outlined
          prepend-icon="mdi-list-status"
          hide-details="auto"
        />
      </v-col>
    <v-col
      cols="12"
      sm="6"
    >
     <v-select
       v-model="contact.assigned_to"
       :items="assignItems"
       item-value="id"
       item-text="name"
       label="Assign to"
       outlined
       prepend-icon="mdi-account"
       hide-details="auto"
     />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
     <v-select
       v-model="contact.membership_type"
       :items="membershipItems"
       item-value="id"
       item-text="name"
       label="Select membership"
       outlined
       prepend-icon="mdi-card-account-details"
       hide-details="auto"
     />
    </v-col>
      <v-col
        cols="12"
        sm="6"
      >
       <v-select
         v-model="contact.contact_source"
         :items="sourceItems"
         item-value="id"
         item-text="name"
         label="Select contact source"
         outlined
         prepend-icon="mdi-account-group"
         hide-details="auto"
       />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="contact.referral_source"
          outlined
          label="Referral source"
          prepend-icon="mdi-account-group"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        v-if="this.$store.getters.user.is_superuser"
      >
        <v-autocomplete
         v-model="staffSelected"
         :items="staffItems"
         :loading="isStaffLoading"
         :search-input.sync="staffSearch"
         hide-no-data
         hide-details="auto"
         item-text="name"
         item-value="id"
         label="Select staff"
         placeholder="Start typing to Search"
         prepend-icon="mdi-face-agent"
         return-object
         @change="selectStaff"
        ></v-autocomplete>
        <!-- <v-select
          v-model="contact.outreach_specialist"
          :items="staffItems"
          item-value="id"
          label="Select specialist"
          outlined
          prepend-icon="mdi-face-agent"
          hide-details="auto"
          :error="contact.outreach_specialist == '' && showError"
          :rules="[v => !!v || 'Field is required']"
        </v-select>> -->
      </v-col>

      <v-col
        cols="12"
      >
        <v-select
          v-model="contact.reason_for_inquiry"
          :items="reasonItems"
          item-value="id"
          item-text="name"
          label="Reason for inquiry"
          outlined
          prepend-icon="mdi-help"
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-textarea
          v-model="contact.notes"
          outlined
          label="Notes"
          prepend-icon="mdi-pen"
          hide-details="auto"
        ></v-textarea>
      </v-col>
    </v-row>
    <DeleteContact
      title="Delete Contact"
      :message="deleteMessage"
      icon="delete"
      action="Delete"
      @submit="deleteItem"/>
    <v-btn
      @click="editContact()"
      x-large
      color="primary"
      bottom
      right
      absolute
      :class="{ 'v-btn--mobile absolute' : $vuetify.breakpoint.xsOnly }"
    >
      Update Contact
    </v-btn>
  </v-form>
</template>
<script>
  import { mapActions } from 'vuex';
  import DeleteContact from '@/components/ConfirmModal.vue';
  //import moment from 'moment';

  export default {
    name: 'EditContact',
    components: {
      DeleteContact
    },
    data() {
      return {
        contact: {
          date_created: '',
          business: '',
          contact_source: '',
          outreach_specialist: '',
          referralto: '',
          referral_source: '',
          reason_for_inquiry: '',
          notes: '',
          referral_type: null,
          intake_completed: false,
          connect: false,
          status: null,
          membership_type: null
        },
        businessSelected: null,
        staffSelected: null,
        typeitems: [
          { id: 0, text:'------'},
          { id: 1, text:'Internal - Technical Assitance'},
          { id: 2, text:'External'}
        ],
        statusItems: [
          { id:0, name:'Close' },
          { id:1, name:'Active' }
        ],
        assignItems: [
          { id: 0, name: 'Jim Nicholas' },
          { id: 1, name: 'Scott Arnold' },
          { id: 2, name: 'Tracey Joseph' },
          { id: 3, name: 'Latrice Allen' }
        ],
        membershipItems:[],
        sourceItems: [],
        overlay: true,
        showError: false,
        businessEntries: [],
        isBusinessLoading: false,
        businessSearch: null,
        staffEntries: [],
        isStaffLoading: false,
        staffSearch: null,
        count: 0,
        alert: false,
        formatted_date: ((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)),
        date_menu: false,
        deleteMessage: 'Are you sure you want to delete this contact?',
        programItems: [],
        reasonItems: []
      }
    },
    computed: {
      businessItems () {
        return this.businessEntries.map(entry => {
          const name = entry.name
          return Object.assign({}, entry, { name })
        })
      },
      staffItems() {
        return this.staffEntries.map(entry => {
          const name = `${entry.first_name} ${entry.last_name}`
          return Object.assign({}, entry, { name })
        })
      },
      convertToLocalDate() {
        // convert to local date since backend sends UTC
        const date_str = this.contact.date_created.substring(0,10)
        const time_str = this.contact.date_created.substring(11)
        const date_split = date_str.split('-').map(x => parseInt(x))
        const time_split = time_str.split(':').map(x => parseInt(x))

        const local_date = new Date((new Date(date_split[0], date_split[1] - 1, date_split[2], time_split[0], time_split[1], time_split[2]) - (new Date()).getTimezoneOffset() * 60000))
        return this.formatDate(local_date)
      },
    },
    created() {
      this.getContact({ id: Number(this.$route.params.id) })
      .then((response) => {
        this.contact = Object.assign({}, this.contact, response.data )

        const split_date = this.convertToLocalDate.split('/')
        this.contact.date_created = `${split_date[2]}-${split_date[0]}-${split_date[1]}`
        this.formatted_date = this.convertToLocalDate

        this.getBusiness({ id: this.contact.business })
        .then(businessres => {
          this.businessEntries = [businessres.data]
          this.businessSelected = this.businessEntries.find((entry) => entry.id == this.contact.business)
        })
        .catch(err => { console.log(err) })

        this.getPrograms()
        .then((response) => {
          this.programItems = response.data.results
        });

        this.getReasons()
        .then((response) => {
          this.reasonItems = response.data.results
        });

        this.getContactSource()
        .then((response) => {
          this.sourceItems = response.data.results
        });

        this.getMembership()
        .then((response) => {
          this.membershipItems = response.data.results
        });

        this.getStaff({ id: this.contact.outreach_specialist })
        .then(specialistresponse => {
          this.staffEntries = [specialistresponse.data]
          this.staffSelected = this.staffEntries.find((entry) => entry.id == this.contact.outreach_specialist)
        })
        .catch(err => { console.log(err) })

        this.overlay = false
      }, () => {});
    },
    methods: {
      ...mapActions(['getAllStaff', 'getStaff', 'searchStaff', 'getContact', 'updateContact', 'getBusinesses', 'deleteContact', 'getBusiness', 'getPrograms', 'getReasons', 'getContactSource', 'getMembership']),
      editContact() {
        this.overlay = true;

        const contact_record = Object.assign({}, this.contact);
        const dateToday = new Date(Date.now() + new Date().getTimezoneOffset() * 60000)
        const timeCreated = `${dateToday.getHours()}:${dateToday.getMinutes()}:00`
        const dateWithTime = `${contact_record.date_created.substring(0, 10)}T${timeCreated}Z`
        //contact_record.date_created = new Date(dateWithTime)
        contact_record.date_created = dateWithTime
        this.updateContact(contact_record)
        .then(() => {
          this.overlay = false;
          this.alert = true
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setTimeout(() => {
            this.alert = false
          }, 3000)
        }, () => {
          this.overlay = false;
        });
      },
      selectBusiness() {
        console.log('val', this.businessSelected)
        //this.contact.business = this.businessSelected.id
        //this.contact.business_name = this.businessSelected.name
      },
      selectStaff(val) {
        this.contact.outreach_specialist = val.id
      },

      datePickerSave (date) {
        this.$refs.menu.save(date)
      },

      formatDate(date) {
        if (!date) return null

        if (date instanceof Date) {
          let year = date.getFullYear();
          let month = (1 + date.getMonth()).toString().padStart(2, '0');
          let day = date.getDate().toString().padStart(2, '0');

          return `${month}/${day}/${year}`;
        }
        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`

      },
      deleteItem() {
        this.deleteContact({id: this.contact.id})
        .then(() => {
          this.deleteMessage = 'Contact deleted. Re-routing...'
          setTimeout(() => {
            this.$router.push({ name: 'contactslist' })
          }, 3000);
        }, (error) => {
          console.log('error', error)
        });
      },
    },
    watch: {
      businessSearch (newVal) {
        this.isBusinessLoading = true
        this.getBusinesses({search:newVal, limit: 10, offset:0})
        .then(res => {
          const { count, results } = res.data
          this.count = count
          this.businessEntries = results
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isBusinessLoading = false))
      },
      staffSearch (newVal, oldVal) {
        if(oldVal){
          this.isStaffLoading = true
          this.searchStaff({name:newVal, limit: 10, offset:0})
          .then(res => {
            this.staffEntries = res.data.results
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isStaffLoading = false))
        }
      },

      contact: {
        handler(val){
          this.formatted_date = this.formatDate(new Date(val.date_created))
          if(val.referral_type !== 2) {
            this.contact.program_name = null
          }
        },
        deep: true
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>
